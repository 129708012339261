import { useEffect, useState } from "react";
import NavLayout from "@/components/NavLayout";
import Button from "@/components/Button";
import Table from "@/components/Table";
import { useGetUserLicensesCollection, useUserCollection } from "@/hooks/user";
import UserAddEditSection from "@/sections/user/UserAddEditSection";
import { Link } from "react-router-dom";
import { userEditSignal } from "@/app/state";
function UserPage() {
  const { data, isLoading } = useGetUserLicensesCollection();
  const [selected, setSelected] = useState<string[]>();
  const [current, setCurrent] = useState<string>();

  useEffect(() => {
    if (current != undefined) {
      const link = document.getElementById("my-link");
      if (link == null) {
        return;
      }
      link!.click();
    }
  }, [current]);

  const users = data?.users ?? [];

  const structuredUsers = users?.map((user: any) => {
    return {
      id: user.id,
      displayName: user.displayName,
      role: user.role,
      license: user.name ?? "",
      // firstName: user.firstName,
      // lastName: user.lastName,
      email: user.email,
      // username: user.username,
    };
  });

  if (isLoading) {
    return <></>;
  }

  return (
    <NavLayout overlayChildren={<UserAddEditSection />}>
      <div className="flex flex-col p-8 gap-y-4">
        <h1 className="text-2xl font-roboto">Users</h1>
        <label
          onClick={() => {
            userEditSignal.value = "add";
          }}
          htmlFor="my-drawer-4"
          className="drawer-button"
        >
          <Button
            className="w-24 text-2xs md:text-xs md:w-40 pointer-events-none btn-info"
            appearance="accent"
          >
            Add User
          </Button>
        </label>
        <Table
          dataSource={structuredUsers ?? []}
          mobileVisibleKeys={["displayName", "role"]}
          hidePrimaryKey={true}
          primaryKey="id"
          hideOptions
          hideCheckbox={true}
          setCheckboxSelected={setSelected}
          checkboxSelected={selected}
          onItemClick={(id: string) => {
            setCurrent(id);
          }}
        />
      </div>
      <Link id="my-link" className="hidden" to={`${current}`}></Link>
    </NavLayout>
  );
}

export default UserPage;
