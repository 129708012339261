import { LegacyRef, useEffect } from "react";
import Button from "@/components/Button";
import Input from "@/components/Input";
import { useForm } from "react-hook-form";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useUpdateIntegrationCollection } from "@/hooks/integration";
import Spinner from "@/components/Spinner";
import { Record } from "pocketbase";

function WorkforceOneSetupServiceSection({
  next,
  integration,
}: {
  next: () => void;
  integration: Record;
}) {
  const animationParent = useAutoAnimate({});
  const { updateIntegrationCollection, updateIntegrationCollectionLoading } =
    useUpdateIntegrationCollection();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    setValue("host", integration.settings.host);
    setValue("username", integration.settings.username);
  }, [integration]);

  const onSubmit = async (formData: any) => {
    const success = await updateIntegrationCollection({
      id: integration.id,
      settings: formData,
    });
    if (success) {
      next();
    }
  };

  const myAnimationParent = animationParent as any;

  return (
    <>
      <div ref={myAnimationParent} className="flex flex-col p-8 gap-y-4">
        <h1 className="text-2xl font-roboto">First Time Setup</h1>
        <p>
          It looks like this is the first time you have set your service for
          Workforce One. Please enter the following information to enable the
          service. (Please note these details cannot be changed)
        </p>
        <form
          className="py-5 flex flex-col gap-y-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            type="text"
            name="host"
            label="Workforce One Host"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Workforce One Host is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
              pattern: {
                value:
                  /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/g,
                message: "Please enter a valid URL",
              },
            }}
            required
          />
          <Input
            type="text"
            name="username"
            label="Username"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Username is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
          <Input
            type="password"
            name="password"
            label="Password"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Password is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
          <div className="pt-8">
            <Button className="w-full text-2xs" type="submit">
              <p className="pr-2">Save</p>
              {updateIntegrationCollectionLoading && <Spinner />}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
}

export default WorkforceOneSetupServiceSection;
