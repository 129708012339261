import { LegacyRef } from "react";
import NavLayout from "@/components/NavLayout";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useParams } from "react-router-dom";
import { useIntegrationCollection } from "@/hooks/integration";
import WorkforceOneManageSection from "@/sections/integration/WorkforceOneManageSection";
function IntegrationManagePage() {
  const animationParent = useAutoAnimate({});
  const myAnimationParent = animationParent as any;
  const { id } = useParams();
  const { integrations, isLoading } = useIntegrationCollection();

  const current = integrations?.filter(
    (integration) => integration.id == id
  )[0];
  //Check Service Type
  //Create Service Steps
  if (isLoading) {
    return (
      <div className="w-full h-full items-center justify-center">
        <span className="loading loading-bars loading-xs"></span>
      </div>
    );
  }

  if (current!.type == "Workforce One Service") {
    return (
      <div ref={myAnimationParent}>
        <NavLayout>
          <WorkforceOneManageSection integration={current!} />
        </NavLayout>
      </div>
    );
  }

  return (
    <div>
      <p>An unknown error has occurred</p>
    </div>
  );
}

export default IntegrationManagePage;
