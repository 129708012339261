import React, { LegacyRef, useEffect, useState } from "react";
import NavLayout from "../../components/NavLayout/NavLayout";
import DashboardTile from "../../components/DashboardTile";
import { useTemplateCollection } from "@/hooks/template";
import { useIntegrationCollection } from "@/hooks/integration";
import { useUserCollection } from "@/hooks/user";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { dashboardWelcomeSignal } from "@/app/state";
import { ClearIcon } from "@/components/Icons/index";
import { useNavigate } from "react-router-dom";
import Grid from "@/components/Grid/Grid";
import Graphic from "@/components/Graphic/Graphic";
import { pb } from '@/lib/pocketbase';
import WarningList from "@/components/WarningList/WarningList";


function DashboardPage() {
  const [dateArray, setDateArray] = useState<Date[]>([])
  const [quantArray, setQuantArray] = useState<number[]>([])

  useEffect(() => {
    (async () => {
      const currentDate = new Date();
      const sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() - 7);
      const sevenDaysAgoString = sevenDaysAgo.toISOString()

      const response = await pb.collection('submission').getFullList(undefined, {
        filter: `created >= "${sevenDaysAgoString}"`,
        $autoCancel: false,
      });

      const counts: {[string: string]: number} = {};

      response.forEach((item) => {
        const date = new Date(item.created);
        const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        if (counts[dateString]) {
          counts[dateString]++;
        } else {
          counts[dateString] = 1;
        }
      });

      setDateArray(Object.keys(counts).map(s => new Date(s)));
      setQuantArray(Object.values(counts));      
    })();
  }, [])

  const [errorArray, setErrorArray] = useState<string[]>([])

  useEffect(() => {
    (async () => {
      const response = await pb.collection("integrationDataSync").getFullList(undefined, {
        filter: 'message != "Success"',
        $autoCancel: false,
      })

      const errorMsgArray: string [] = response.map((item) => item['message'])
      setErrorArray(errorMsgArray)
    })();
  }, [])

  const animationParent = useAutoAnimate({});
  const myAnimationParent = animationParent as any;
  const { users, isLoading: usersAreLoading } = useUserCollection();
  const { templates, isLoading: templatesAreLoading } = useTemplateCollection();
  const { integrations, isLoading: integrationsAreLoading } =
    useIntegrationCollection();
  const navigate = useNavigate();

  if (templatesAreLoading || usersAreLoading || integrationsAreLoading) {
    return <></>;
  }

  return (
    <NavLayout>
      <div ref={myAnimationParent}>
        {dashboardWelcomeSignal.value && (
          <div className="h-52 w-[37.2%] mt-16 px-6 mb-2">
            <div className="bg-gradient-to-r to-secondary via-primary from-info w-full h-full flex flex-col rounded-lg">
              <div
                onClick={() => {
                  dashboardWelcomeSignal.value = false;
                }}
                className="self-end p-4"
              >
                <ClearIcon fill="white" />
              </div>
              <div className="h-full w-full flex flex-col justify-center pb-10 px-10">
                <h1 className="font-raleway text-4xl font-extrabold">
                  Welcome
                </h1>
                <p className=" text-base-content text-xl pt-2 pb-1 font-arimo font-medium max-w-xs">
                  Are You Ready For Better Signing And Less Admin?
                </p>
              </div>
            </div>
          </div>
        )}        
          <div className="flex flex-row flex-wrap px-4">  
            <Grid>              
              <DashboardTile
              title="Submissions"
              colspan="1">

                <Graphic dates={dateArray} quant={quantArray}/>
              </DashboardTile>

              <DashboardTile
              title={"Warnings"}
              colspan="1">
                <WarningList warnings={errorArray}/>
              
              </DashboardTile>
            </Grid>          
        </div>
      </div>
    </NavLayout>
  );
}

export default DashboardPage;
