import { LegacyRef, useEffect, useState } from "react";
import Button from "@/components/Button";
import Table from "@/components/Table";
import NavLayout from "@/components/NavLayout";
import {
  useDeleteTemplateCollection,
  useTemplateCollection,
  useCreateTemplateCollection,
  useUpdateTemplateCollection,
} from "@/hooks/template";
import Input from "@/components/Input";
import { useForm } from "react-hook-form";
import Dropzone from "@/components/Dropzone";
import TemplateSettingsSection from "@/sections/template/TemplateSettingsSection";
import { useAutoAnimate } from "@formkit/auto-animate/react";
function TemplatePage() {
  const animationParent = useAutoAnimate({});
  const { templates, isLoading } = useTemplateCollection();
  const { deleteTemplateCollection } = useDeleteTemplateCollection();
  const { updateTemplateCollection } = useUpdateTemplateCollection();
  const { createTemplateCollection, createTemplateCollectionError } =
    useCreateTemplateCollection();
  const [selected, setSelected] = useState<string[]>();
  const [current, setCurrent] = useState<string>();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    console.log("selected");
    console.log(selected);
    console.log("current");
    console.log(current);
  }, [selected, current]);

  const addForms = async (files: File[]) => {
    let tally: (boolean | undefined)[] = [];

    for await (const file of files) {
      try {
        const formData = new FormData();
        formData.append("settings", "null");
        formData.append("file", "");
        formData.append("name", file.name.split(".").slice(0, -1).join("."));
        formData.append("size", `${file.size}`);
        formData.append("file", file);
        const res = await createTemplateCollection(formData);
        tally?.push(createTemplateCollectionError ? undefined : true);
        tally?.push(!res ? undefined : true);
      } catch (error) {
        tally?.push(undefined);
      }
    }
    console.log("tally");
    console.log(tally);

    if (tally.includes(undefined)) {
      return undefined;
    } else {
      return tally;
    }
  };

  const onSubmit = async (formData: any) => {
    const data = {
      email: formData.email,
      enabled: true,
    };
    await updateTemplateCollection({ id: selected, settings: data });
    setSelected([]);
  };

  const structured = templates?.map((data: any) => {
    return {
      id: data.id,
      name: data.name,
      size: data.size,
      created: data.created,
    };
  });
  const myAnimationParent = animationParent as any;

  if (isLoading) {
    return (
      <div className="w-full h-full items-center justify-center">
        <span className="loading loading-bars loading-xs"></span>
      </div>
    );
  }

  return (
    <div>
      <NavLayout
        overlayChildren={
          <TemplateSettingsSection
            data={
              (templates?.filter((x) => x.id == current) ?? [
                { name: "undefined" },
              ])[0]
            }
          />
        }
      >
        <div ref={myAnimationParent} className="flex flex-col p-8 gap-y-4">
          <h1 className="text-2xl font-roboto">Templates</h1>
          {(selected ?? []).length > 0 ? (
            <div className="flex flex-row justify-between gap-x-2 w-full">
              <label htmlFor="modal-delete-templates" className="">
                <Button
                  className="w-full pointer-events-none text-2xs md:text-xs md:w-40"
                  appearance="destructive"
                >
                  Delete Template{(selected ?? []).length > 1 ? "s" : ""}
                </Button>
              </label>
              <div className="flex flex-row gap-x-2">
                {/* TODO: Implement Feature  */}
                {/* <label htmlFor="modal-forward-templates" className="">
                  <Button
                    className="w-full pointer-events-none text-2xs md:text-xs md:w-40"
                    appearance="additive"
                  >
                    Request Fill
                  </Button>
                </label> */}
                <label htmlFor="modal-forward-templates" className="">
                  <Button
                    className="w-full pointer-events-none text-2xs md:text-xs md:w-40"
                    appearance="accent"
                  >
                    Forward Template{(selected ?? []).length > 1 ? "s" : ""}
                  </Button>
                </label>
              </div>
            </div>
          ) : (
            <Dropzone onDrop={addForms} />
          )}
          {structured?.length == 0 ? (
            <div className="text-center pt-10">
              <i>Start uploading to see your templates.</i>
            </div>
          ) : (
            <Table
              dataSource={structured ?? []}
              mobileVisibleKeys={["name", "created"]}
              hidePrimaryKey={true}
              primaryKey="id"
              hideOptions
              hideCheckbox={false}
              setCheckboxSelected={setSelected}
              checkboxSelected={selected}
              onItemClick={(id: string) => {
                const link = document.getElementById("my-link");
                if (link == null) {
                  return;
                }
                setCurrent(id);
                link!.click();
              }}
            />
          )}
          {/* {
            (templates ?? []).length > 5 &&
            <div className="btn-group self-center pt-1">
              <button className="btn btn-md">1</button>
              <button className="btn btn-md btn-active">2</button>
              <button className="btn btn-md">3</button>
              <button className="btn btn-md">4</button>
            </div>
          } */}
          {/* This is to open the right side drawer */}
          <label
            id="my-link"
            htmlFor="my-drawer-4"
            className="drawer-button btn btn-info hidden"
          >
            Open drawer
          </label>
        </div>
      </NavLayout>
      <input
        type="checkbox"
        id="modal-delete-templates"
        className="modal-toggle"
      />
      <label
        htmlFor="modal-delete-templates"
        className="modal cursor-pointer modal-bottom sm:modal-middle"
      >
        <label className="modal-box relative" htmlFor="">
          <h3 className="text-lg font-bold text-base-content">
            Are you sure you want to delete these templates?
          </h3>
          <p className="py-4">
            The action you are about to perform is irreversible and these
            templates will be permenatly deleted.
          </p>
          <div className="flex flex-row justify-between">
            <div className="modal-action">
              <label
                htmlFor="modal-delete-templates"
                className=""
                onClick={() => {
                  setSelected([]);
                  deleteTemplateCollection(selected ?? []);
                }}
              >
                <Button
                  className="w-full pointer-events-none text-2xs"
                  appearance="destructive"
                >
                  Delete
                </Button>
              </label>
            </div>
            <div className="modal-action w-full">
              <label htmlFor="modal-delete-templates" className="">
                <Button
                  className="w-full pointer-events-none text-2xs"
                  appearance="secondary"
                >
                  Cancel
                </Button>
              </label>
            </div>
          </div>
        </label>
      </label>
      <input
        type="checkbox"
        id="modal-forward-templates"
        className="modal-toggle"
      />
      <label
        htmlFor="modal-forward-templates"
        className="modal cursor-pointer modal-bottom sm:modal-middle"
      >
        <label className="modal-box relative" htmlFor="">
          <h3 className="text-lg font-bold text-base-content">
            Please enter a valid email address to forward templates to on
            submission.
          </h3>
          <form className="py-5" onSubmit={handleSubmit(onSubmit)}>
            <Input
              type="email"
              name="email"
              label="Email Address"
              errors={errors}
              register={register}
              validationSchema={{
                required: "Email is required",
                minLength: {
                  value: 3,
                  message: "Please enter a minimum of 3 characters",
                },
              }}
              required
            />
            <div className="flex flex-row justify-between">
              <div className="modal-action">
                <Button
                  className="w-full text-2xs"
                  appearance="accent"
                  type="submit"
                >
                  <label htmlFor="modal-forward-templates">Forward</label>
                </Button>
              </div>
              <div className="modal-action">
                <label htmlFor="modal-forward-templates" className="">
                  <Button
                    className="w-full pointer-events-none text-2xs"
                    appearance="secondary"
                  >
                    Cancel
                  </Button>
                </label>
              </div>
            </div>
          </form>
        </label>
      </label>
    </div>
  );
}

export default TemplatePage;
