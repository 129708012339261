import { pb } from "@/lib/pocketbase";
import { useQuery } from "@tanstack/react-query";

export const INTEGRATION_DATA_QUERY_KEY = 'integrationData';

export const getIntegrationDataCollection = ({integrationId}:{integrationId:string}) => {
  try {
    return pb.collection('integrationData').getFullList(undefined, {
        filter: `(integrationId='${integrationId}')`,
    });
  } catch (err) {
    return undefined;
  }
};

export function useIntegrationDataCollection({integrationId}:{integrationId:string}) {
  const { isLoading, isError, data, error } = useQuery(
    [INTEGRATION_DATA_QUERY_KEY],
    () => getIntegrationDataCollection({integrationId: integrationId}),
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      // staleTime: 30_000, // 3s
    },
  );

  return {
    integrationData: data,
    isError,
    isLoading,
    error: error as Error,
  };
}