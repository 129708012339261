import { pb } from "@/lib/pocketbase";
import { useQuery } from "@tanstack/react-query";

export const REPORT_QUERY_KEY = 'report';

export const getReportCollection = () => {
  try {
    return pb.collection('submissionReport').getFullList(200, {
		expand: 'userId,submissionId',
	});
  } catch (err) {
    return [];
  }
};

export function useReportCollection() {
  const { isLoading, isError, data, error } = useQuery(
    [REPORT_QUERY_KEY],
    () => getReportCollection(),
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      // staleTime: 30_000, // 3s
    },
  );

  return {
    reports: data,
    isError,
    isLoading,
    error: error as Error,
  };
}