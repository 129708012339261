import { warning } from "@/utils/colors"
import WarningComponent from "./WarningComponent"

interface WarningListProps {
    warnings: string[]
}

function WarningList(props: WarningListProps) {
    if (props.warnings.length === 0) {
        return (
            <div className="flex flex-col justify-center items-center w-full min-h-full">
                <p className="font-raleway font-semibold text-[1.5rem] text-center">
                    Nothing To Report
                </p>

                <p className="font-arimo font-normal text-xl text-center">
                    Great News. Looks like everything is<br/>running smoothly!
                </p>
            </div>
        )
    }
    else {
        return (
            <div className="flex flex-col justify-start pr-5 gap-2 min-w-full max-h-[22.5rem] overflow-y-auto">
                {props.warnings.map((warning, i) =>
                    <WarningComponent key={i} warningText={warning}/>
                )}
            </div>
        )
    }
}

export default WarningList