import { useEffect, useState } from "react";
import Button from "@/components/Button";
import Table from "@/components/Table";
import { useUserCollection } from "@/hooks/user";
import useWindowDimensions from "@/hooks/windowDimentions";
import { Record } from "pocketbase";
import { useUpdateIntegrationCollection } from "@/hooks/integration";
import { useNavigate } from "react-router-dom";
import Input from "@/components/Input";
import { useForm } from "react-hook-form";
function WorkforceOneSetupUsersSection({
  integration,
}: {
  integration: Record;
}) {
  type IntegrationData = {
    key: string;
    value: string;
  };
  const { users, isLoading, isError } = useUserCollection();
  const { width } = useWindowDimensions();
  const { updateIntegrationCollection } = useUpdateIntegrationCollection();
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [current, setCurrent] = useState<string>();
  const [currentUser, setCurrentUser] = useState<string>();
  const [integrationData, _] = useState<IntegrationData[]>([
    {
      key: "139",
      value: "Stewart Allen",
    },
    {
      key: "122",
      value: "Stevie OConnell",
    },
  ]);

  useEffect(() => {
    if (current !== undefined) {
      if (
        integration.settings.users === undefined
          ? true
          : !(
              integration.settings.users.filter((x: any) => x.id === current)
                .length > 0
            )
      ) {
        const link = document.getElementById("my-link");
        if (link == null) {
          return;
        }
        link!.click();
      }
    }
    setCurrent(undefined);
  }, [current]);

  const onSubmit = async (formData: any) => {
    const newSettings = JSON.parse(JSON.stringify(integration.settings));
    newSettings.fallbackEmail = formData.email;
    const success = await updateIntegrationCollection({
      id: integration.id,
      isSetup: true,
      settings: newSettings,
    });
    if (success) {
      navigate(`/integrations/${integration.id}`);
    }
  };

  const structuredUsers = users?.map((user: any) => {
    return {
      id: user.id,
      displayName: user.displayName,
      email: user.email,
      configured:
        integration.settings.users == undefined
          ? "N"
          : `${
              integration.settings.users.filter((x: any) => x.id == user.id)
                .length > 0
                ? "Y"
                : "N"
            }`,
    };
  });

  if (isLoading) {
    return <></>;
  }
  if (isError) {
    return <>isError...</>;
  }

  const DropDown = () => {
    const [selectedExternalUser, setSelectedExternalUser] =
      useState<IntegrationData>();
    useEffect(() => {
      setSelectedExternalUser(integrationData[0]);
    }, [integrationData]);

    const handleSet = async () => {
      const settings = JSON.parse(JSON.stringify(integration.settings));
      if (settings.users == undefined) {
        settings.users = [
          {
            id: currentUser,
            externalId: selectedExternalUser?.key,
          },
        ];
      } else {
        settings.users = [
          ...integration.settings.users,
          {
            id: currentUser,
            externalId: selectedExternalUser?.key,
          },
        ];
      }
      const result = { id: integration.id, settings: settings };
      console.log("result");
      console.log(result);

      const success = await updateIntegrationCollection({
        id: integration.id,
        settings: settings,
      });
      if (success) {
      }
    };

    if (selectedExternalUser == undefined) {
      <div className="w-full h-full items-center justify-center">
        <span className="loading loading-bars loading-xs"></span>
      </div>;
    }
    return (
      <label className="modal-box relative w-full overflow-visible" htmlFor="">
        <h3 className="text-lg font-bold text-base-content pb-3">
          Please Select WorkforceOne Id
        </h3>
        <div className={`dropdown ${width < 640 ? "dropdown-top" : ""} w-full`}>
          <label
            tabIndex={0}
            className="btn m-1 w-full bg-slate-200 text-base-content capitalize"
          >
            {selectedExternalUser?.value} ({selectedExternalUser?.key})
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content menu p-2 shadow bg-slate-400 rounded-box w-full text-base-content flex flex-col items-center"
          >
            {integrationData.map((x) => {
              return (
                <li
                  key={x.key}
                  onClick={() => {
                    setSelectedExternalUser(x);
                  }}
                  className="w-full"
                >
                  <a>
                    {x.value} ({x.key})
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex flex-row justify-between">
          <div className="modal-action">
            <label
              onClick={handleSet}
              htmlFor="modal-set-integration"
              className=""
            >
              <Button
                className="w-20 pointer-events-none text-2xs"
                appearance="accent"
              >
                Set
              </Button>
            </label>
          </div>
          <div className="modal-action w-full">
            <label htmlFor="modal-set-integration" className="">
              <Button
                className="w-20 pointer-events-none text-2xs"
                appearance="secondary"
              >
                Cancel
              </Button>
            </label>
          </div>
        </div>
      </label>
    );
  };

  return (
    <>
      <div className="flex flex-col p-8 gap-y-4 h-full">
        <h1 className="text-2xl font-roboto">Configure your first users</h1>
        <p>
          These users need to be manually configured with their workforce one
          consultantId’s
        </p>
        <div>
          <Table
            dataSource={structuredUsers ?? []}
            mobileVisibleKeys={["displayName", "email", "configured"]}
            hidePrimaryKey={true}
            primaryKey="id"
            hideOptions
            onItemClick={(id: string) => {
              setCurrent(id);
              setCurrentUser(id);
            }}
          />
        </div>
        <h1 className="text-2xl font-roboto pt-4">Fallback Email</h1>
        <p>
          Please enter an email that you would like to send to in the event the
          connector doesn’t connect.
        </p>
        <form
          className="flex flex-col gap-y-4 h-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Input
            type="email"
            name="email"
            label="Email Address"
            errors={errors}
            register={register}
            validationSchema={{
              required: "Email is required",
              minLength: {
                value: 3,
                message: "Please enter a minimum of 3 characters",
              },
            }}
            required
          />
          <button className="btn btn-info w-28 sm:my-0" type="submit">
            Save
          </button>
        </form>
      </div>
      <label
        id="my-link"
        htmlFor="modal-set-integration"
        className="hidden"
      ></label>
      <input
        type="checkbox"
        id="modal-set-integration"
        className="modal-toggle"
      />
      <label
        htmlFor="modal-set-integration"
        className="modal cursor-pointer modal-bottom sm:modal-middle overflow-visible"
      >
        <DropDown />
      </label>
    </>
  );
}

export default WorkforceOneSetupUsersSection;
