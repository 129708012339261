import { Record } from "pocketbase";
import { useEffect } from "react";

function ReportManageSection({ report }: { report: Record | undefined }) {
  useEffect(() => {
    console.log((report as unknown as any).expand.submissionId.name);
  }, [report]);

  const colorSwitch = (color: string) => {
    switch (color) {
      case "green":
        return "bg-accept-light";
      case "red":
        return "bg-destruction-dark";
      default:
        return "bg-slate-900";
    }
  };

  if (report == undefined) {
    return (
      <div className="w-full h-full items-center justify-center">
        <span className="loading loading-bars loading-xs"></span>
      </div>
    );
  }
  return (
    <div className="pt-20 px-4 flex flex-col h-full gap-y-2">
      <h1 className="text-3xl pb-2 capitalize">
        {(report as unknown as any).expand.submissionId.name}
      </h1>
      <p>Here is a breakdown of what happened with the file.</p>
      <ul className="flex flex-col gap-y-2 py-8">
        {(report.report == undefined ? [] : report.report).map(
          (report: any) => {
            return (
              <li className="">
                <div
                  tabIndex={0}
                  className="collapse flex flex-col bg-slate-200 p-0 focus:text-slate-900 items-start pl-3"
                >
                  <div className="my-auto ">
                    <div className="flex flex-row bg-slate-200 pt-3 pb-2  items-center">
                      <div className="py-0 pr-2">
                        <div
                          className={`h-3 w-3 ${colorSwitch(
                            report.colorCode
                          )} rounded-full`}
                        ></div>
                      </div>
                      <p className="text-base-content p-0 text-sm font-bold">
                        {report.action}
                      </p>
                    </div>
                    <div className="collapse-content pl-5">
                      <ul className="text-sm  pointer-events-none">
                        <li className="pointer-events-none">
                          {report.details}
                        </li>
                        <li className="text-xs pointer-events-none">
                          <i className="p-0 m-0">{report.timestamp}</i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            );
          }
        )}
      </ul>
      {report.report.some((x: any) => x.colorCode == "red") && (
        <p className="text-destruction-dark text-xs pb-8">
          There may be a problem with this file please contact the software
          vendors.
        </p>
      )}
      <div className="btn btn-info capitalize">
        <a
          href="https://www.biz365.com.au/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          Support
        </a>
      </div>
    </div>
  );
}

export default ReportManageSection;
